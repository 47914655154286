'use client';

import {useTranslation}  from 'foundations/i18n/i18n';
import Image             from 'next/image';
import AstroWaitingImage from 'foundations/brand/astro/astro-waiting.png';

export function NotFoundPage() {
    const {t} = useTranslation();
    return (
        <div className={'pt-12 pb-20 text-center'}>
            <Image className={'mx-auto'} src={AstroWaitingImage} alt={'astro'} height={400}/>
            <h1 className={'font-black text-5xl block mb-4'}>{t('Houston, we have a problem')}</h1>
            <span className={'text-xl block mb-3'}>{t('This page is no longer available, or may have never existed.')}</span>
            <a className={'text-underline text-g-secondary hover:underline cursor-pointer'} onClick={() => window.history.back()}>{t('<- Go Back')}</a>
        </div>
    );
}